import gql from "graphql-tag";

export const EMPLOYEE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      id
      firstName
      lastName
      workEmail
      profile {
        imageUrl
      }
      employment {
        staffNumber
        hiredDate
        passive
        branch {
          name
        }
        unit {
          name
        }
        team {
          name
        }
        jobInfo {
          name
          definitions
        }
      }
    }
  }
`;

export const EMPLOYEE_PROFILE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      firstName
      lastName
      identityNumber
      phone
      workEmail
      serviceLine {
        id
        name
        driverName
        driverPhone
      }
    }
  }
`;

export const EMPLOYEE_LANGUAGE_ROLE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      profile {
        language
        role {
          id
          name
        }
      }
    }
  }
`;

export const EMPLOYEE_CONTACT = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      contact {
        address
        email
        secondaryPhone
        workPhone
        fixedPhone
        extensionNumber
        spousePhone
        emergencyContactName
        emergencyContactPhone
        objectAttributeValues {
          name
          value
          type
          key
        }
      }
    }
  }
`;

export const EMPLOYEE_PERSONAL = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      personal {
        birthDate
        placeOfBirth
        nationality
        bloodType
        gender
        militaryService
        levelOfDisability
        exConvict
        driver
        passportNumber
        militaryServiceSuspendedUntilDate
        expireDate
        hesCode
        objectAttributeValues {
          name
          value
          type
          key
        }
      }
    }
  }
`;

export const EMPLOYEE_FAMILY = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      family {
        maritalStatus
        spouseName
        childCount
        tenant
        martyrsRelatives
        disabilityChildCount
        dateOfMarriage
        fatherName
        motherName
        mothersMaidenName
        siblingCount
        childInfos {
          name
          birthDate
          graduation
        }
        objectAttributeValues {
          name
          value
          type
          key
        }
      }
    }
  }
`;

export const EMPLOYEE_EDUCATION = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      education {
        school
        faculty
        graduation
        graduationGrade
        academicTitle
        department
        languages
        licenseUrl
        objectAttributeValues {
          name
          value
          type
          key
        }
      }
    }
  }
`;

export const EMPLOYEE_EMPLOYMENT = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      employment {
        allowanceDate
        staffNumber
        passive
        ssn
        hiredDate
        startedDate
        employmentType
        collarType
        haveAnInterview
        integrationValue
        researchAndDevelopmentPerson
        socialSecurityType
        quitJobReason {
          name
          description
        }
        quitJobReasonDescription
        quitJobReasonDetail {
          id
          color
          name
          description
        }
        quitJobDate
        branch {
          id
          name
          color
        }
        unit {
          id
          name
          color
        }
        team {
          id
          name
          color
        }
        jobInfo {
          id
          name
          color
        }
        agency {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export const EMPLOYEE_FINANCIAL = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      financial {
        iban
        ticketNumber
        bankAccountNumber
        bankBranch
        healthInsurancePersonCount
        netPay
        previousSalary {
          amount
          code
        }
        monthlyStoppage {
          amount
          code
        }
        salary {
          amount
          code
        }
        ticketPrice {
          amount
          code
        }
        previousTicketPrice {
          amount
          code
        }
        agi {
          amount
          code
        }
        grossSalary {
          amount
          code
        }
        yearlyPremium {
          amount
          code
        }
        objectAttributeValues {
          name
          value
          type
          key
        }
      }
    }
  }
`;

export const EMPLOYEE_SOCIAL_MEDIA = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      socialMedia {
        linkedin
        facebook
        instagram
        twitter
        skype
        objectAttributeValues {
          name
          value
          type
          key
        }
      }
    }
  }
`;

export const EMPLOYEE_BODY_SIZE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      bodySize {
        weight
        height
        shirtSize
        pantsSize
        shoeSize
        coat
        waistcoat
        objectAttributeValues {
          name
          value
          type
          key
        }
      }
    }
  }
`;

export const EMPLOYEE_MOBILE_DEVICE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      mobileDevice {
        multipleDeviceAllowed
        serialNumber
      }
    }
  }
`;

export const EMPLOYMENT = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      employment {
        staffNumber
        passive
        quitJobReason {
          name
          description
        }
        quitJobDate
        haveAnInterview
        branch {
          id
          name
          color
        }
        unit {
          id
          name
          color
        }
        team {
          id
          name
          color
        }
        jobInfo {
          id
          name
          color
        }
        agency {
          id
          name
        }
        ssn
        hiredDate
        startedDate
        employmentType
        collarType
      }
    }
    units {
      id
      color
      name
      parent {
        id
      }
    }
    branches {
      id
      color
      name
    }

    agencies {
      id
      name
    }
    jobInfos {
      id
      color
      name
    }
    teams {
      id
      color
      name
    }
  }
`;

export const MANAGERS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      management {
        manager {
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
      }
      timeSettings {
        shiftManagers {
          rank
          manager {
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
        }
        overtimeManagers {
          rank
          manager {
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
        }
      }
      leaveSettings {
        leaveManagers {
          rank
          manager {
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
        }
      }
      expenseSettings {
        expenseManagers {
          rank
          manager {
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PAYROLL = gql`
  query filterWithPaginatePayrolls($criteria: PayrollFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginatePayrolls(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        date
        url
        createdDateTime
      }
    }
  }
`;

export const DEBIT_TICKETS = gql`
  query filterWithPaginateDebitTickets($criteria: DebitTicketFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateDebitTickets(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }

      content {
        id
        asset {
          id
          name
        }
        dueDate
        borrowDate
        giveBackDate
        quantity
      }
    }
  }
`;

export const VEHICLES = gql`
  query filterWithPaginateVehicles($criteria: VehicleFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateVehicles(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        vehicleModel {
          id
          name
          vehicleBrand {
            id
            name
          }
        }
        employee {
          id
          firstName
          lastName
          employment {
            branch {
              id
              name
            }
          }
        }
        plateNumber
        inspection
        licenceHolder
        color
        commercial
        companyLogoExists
        year
        description
      }
    }
  }
`;

export const PAPER_PAGINATE = gql`
  query filterWithPaginatePapers($criteria: PaperFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginatePapers(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        paperType {
          id
          name
        }
        signatureStatus
        url
        issueDate
        expireDate
      }
    }
  }
`;
export const PAPERS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      papers {
        id
        paperType {
          id
          name
        }

        url
        issueDate
        expireDate
      }
    }
  }
`;

export const DOCUMENTS = gql`
  query FilterWithPaginateDocuments($criteria: DocumentFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateDocuments(criteria: $criteria, pagination: $pagination) {
      content {
        id
        name
        type {
          id
          name
        }
        sender {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        url
        createdDateTime
      }
      pageInfo {
        totalElements
        totalPages
      }
    }
  }
`;

export const PERFORMANCE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      waitingPerformanceEvaluations {
        id

        evaluation {
          name
          validationBeginDate
          validationEndDate
          expirationBeginDate
          expirationEndDate
        }
        evaluatee {
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        published
        publishedDateTime
      }
    }
  }
`;

export const DISEASES_PAGINATE = gql`
  query filterWithPaginateDiseases($criteria: DiseaseFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateDiseases(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        name
        category {
          id
          name
        }
        importance
        fromDate
        toDate
        medicines {
          id
          name
        }
        description
      }
    }
  }
`;
export const DISEASES = gql`
  query FilterDisease($criteria: DiseaseFilterCriteria!) {
    filterDiseases(criteria: $criteria) {
      id
      name
      category {
        id
        name
      }
      importance
      fromDate
      toDate
      medicines {
        id
        name
      }
      description
    }
  }
`;

export const DISEASE_DETAIL = gql`
  query disease($id: ID!) {
    disease(id: $id) {
      id
      name
      category {
        id
        name
      }
      importance
      fromDate
      toDate
      medicines {
        id
        name
      }
      description
      attachment
    }
  }
`;

export const EVENTS = gql`
  query filterWithPaginateActivities($criteria: ActivityFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateActivities(criteria: $criteria, pagination: $pagination) {
      content {
        id
        name
        activityType {
          name
        }
        beginDate
        endDate
        owner {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
        }
        online
      }
      pageInfo {
        totalElements
      }
    }
  }
`;
export const TRAININGS_PAGINATE = gql`
  query filterWithPaginateTrainingPlans($criteria: TrainingPlanFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateTrainingPlans(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        name
        training {
          name
        }
        trainingType

        organiser {
          id
          firstName
          lastName
        }
        externalLecturer
        lecturer {
          id
          firstName
          lastName
        }
        location
        trainingSessions {
          id
          beginDateTime
          endDateTime
        }
        terminal {
          name
        }
        takeTheRegister
        participantDeclaration
      }
    }
  }
`;
export const TRAININGS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      trainingPlansByParticipants {
        name
        training {
          name
        }
        trainingType

        organiser {
          id
          firstName
          lastName
        }
        lecturer {
          id
          firstName
          lastName
        }
        location
        trainingSessions {
          beginDateTime
          endDateTime
        }
        terminal {
          name
        }
        takeTheRegister
        participantDeclaration
      }
    }
  }
`;

export const TRAINING_DETAIL = gql`
  query TrainingPlan($id: ID!) {
    trainingPlan(id: $id) {
      id
      name
      color
      training {
        id
        name
        color
      }
      trainingType
      externalLecturer
      lecturer {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
      }
      participants {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
      }
      fundedByInternalSources
      organiser {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
      }
      trainingEvaluationTypes {
        id
        name
        evaluatedType
        trainingScoreType
      }
      location
      takeTheRegister
      participantDeclaration
      terminal {
        id
        name
        terminalType
      }
      trainingSessions {
        id
        beginDateTime
        endDateTime
      }
      description
    }
  }
`;

export const EMPLOYEE_MANAGERS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      expenseSettings {
        approver
      }
    }
  }
`;

export const MENUS = gql`
  query filterWithPaginateMenus($criteria: MenuFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateMenus(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        name
        color
        date
        items
      }
    }
  }
`;

export const EMPLOYEES = gql`
  query filterWithPaginateEmployees($criteria: EmployeeFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateEmployees(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        firstName
        lastName
        profile {
          imageUrl
        }
        workEmail
        contact {
          workPhone
        }
        employment {
          branch {
            name
          }
        }
      }
    }
  }
`;

export const APPROVAL_EXPENSE_REQUESTS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      approvalExpenseRequests {
        id
        note
        approved
        nextApprover
        approvedAmount
        expense {
          name
          type {
            name
          }
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          requested {
            amount
            code
          }
          approved {
            amount
            code
          }
          attachment
          requestStatus
          timestamp
          description
          travel {
            id
            beginDate
            endDate
            startWorkDate
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
            address
            country
            city
            region
            requestStatus
          }
          expenseManagement {
            id
            name
            project {
              name
              color
            }
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const APPROVAL_IMPREST_REQUESTS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      approvalImprestRequests {
        id
        note
        approved
        nextApprover
        approvedAmount
        imprest {
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          period
          requested {
            amount
            code
          }
          approved {
            amount
            code
          }
          requestStatus
          timestamp
          travel {
            id
            beginDate
            endDate
            startWorkDate
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
            address
            country
            city
            region
            requestStatus
          }
          expenseManagement {
            id
            name
            project {
              name
              color
            }
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const APPROVAL_TRAVEL_REQUESTS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      approvalTravelRequests {
        id
        note
        approved
        nextApprover
        travel {
          id
          requester {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          beginDate
          endDate
          startWorkDate
          isDomestic
          address
          country
          city
          region
          travelDescription
          reason
          requestStatus
          leaveRequest {
            id
            requester {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
            }
            leaveType {
              id
              name
            }
            leaveRequestDays {
              date
              duration
              period
              fromTime
            }
            leaveRequestStatus
          }
          expenses {
            id
            name
            type {
              name
            }
            timestamp
            requestStatus
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
            }
            abolisher {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
            }
            requested {
              amount
              code
            }
            approved {
              amount
              code
            }
          }
          imprests {
            id
            period
            timestamp
            requestStatus
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
            }
            abolisher {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
            }
            requested {
              amount
              code
            }
            approved {
              amount
              code
            }
          }
          debits {
            id
            asset {
              id
              name
              assetCategory {
                id
                name
              }
              assetModel {
                id
                name
              }
              serialNumber
            }
            borrowDate
            dueDate
            giveBackDate
            quantity
          }
          travelRequestProcesses {
            id
            approver {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
            }
            approved
            note
            eventDateTime
            nextApprover
          }
          travelDetails {
            id
            travelDetailType
            travelDetailSubType
            beginDate
            endDate
            isRentCar
            isShuttle
            comment
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_PROFILE_SETTINGS = gql`
  {
    organizationDetail {
      id
      name
      profileSettings {
        category
        editActive
      }
      profileSettingsInfo
    }
  }
`;
